@import '../../themes/common.scss';

.sidebar__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(assets/billing.svg)
      url(assets/billing_active.svg)
      url(assets/containers.svg)
      url(assets/containers_active.svg)
      url(assets/dashboard.svg)
      url(assets/dashboard_active.svg)
      url(assets/files.svg)
      url(assets/files_active.svg)
      url(assets/settings.svg)
      url(assets/settings_active.svg)
      url(assets/logo.svg);
  }

  .sidebar__title {
    display: flex;
    align-items: center;
    padding: rem(40) 0 rem(36) rem(40);
    color: $text_color_1;
    font-family: $font_2;
    font-size: em(20);
    line-height: rem(28);
    box-sizing: border-box;

    span {
      margin: 0 0 0 rem(4);
      font-family: $font_4;
    }

    &::before {
      content: '';
      display: block;
      min-width: rem(24);
      width: rem(24);
      min-height: rem(24);
      height: rem(24);
      margin: 0 rem(12) 0 0;
      background-image: url(assets/logo.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .sidebar__nav_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .sidebar__item_link {
    position: relative;
    display: flex;
    align-items: center;
    min-height: rem(49);
    height: rem(49);
    margin: 0 0 rem(18) 0;
    color: $text_color_1;
    font-family: $font_2;
    font-size: em(18);
    line-height: rem(25);
    text-decoration: none;
    border: rem(1) solid transparent;
    border-right: none;
    box-sizing: border-box;
    
    &:hover {
      background: linear-gradient($bg_color_7);
      border-image-slice: 1;
      border-image-source: linear-gradient(92.31deg, rgba(0, 184, 242, 0.3) 0%, rgba(0, 184, 242, 0.06) 100%);
      cursor: pointer;
    }
    
    &::before {
      content: '';
      display: block;
      min-width: rem(20);
      width: rem(20);
      min-height: rem(20);
      height: rem(20);
      margin: 0 rem(14) 0 rem(40);
      background-repeat: no-repeat;
      background-size: contain;
    }

    &--dashboard {
      &::before {
        background-image: url(assets/dashboard.svg);
      }
    }

    &--containers {
      &::before {
        background-image: url(assets/containers.svg);
      }
    }

    &--files {
      &::before {
        background-image: url(assets/files.svg);
      }
    }

    &--billing {
      &::before {
        background-image: url(assets/billing.svg);
      }
    }

    &--settings {
      &::before {
        background-image: url(assets/settings.svg);
      }
    }
    &--users {
      &::before {
        background-image: url(assets/users.svg);
      }
    }
  }

 .active.sidebar__item_link {
    color: $text_color_3;
    background: transparent;

    &:hover {
      background: linear-gradient($bg_color_8);
    }

    &--dashboard {
      &::before {
        background-image: url(assets/dashboard_active.svg);
      }
    }

    &--containers {
      &::before {
        background-image: url(assets/containers_active.svg);
      }
    }

    &--files {
      &::before {
        background-image: url(assets/files_active.svg);
      }
    }

    &--billing {
      &::before {
        background-image: url(assets/billing_active.svg);
      }
    }

    &--settings {
      &::before {
        background-image: url(assets/settings_active.svg);
      }
    }
    &--users {
      &::before {
        background-image: url(assets/users_active.svg);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: rem(-1);
      left: rem(-1);
      display: block;
      width: rem(6);
      height: calc(100% + 0.125rem);
      margin: 0 rem(38) 0 0;
      background-color: $bg_color_9;
    }
  }
}