$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

$text_color_1: #ffffff;
$text_color_2: #000000;
$text_color_3: #00B8F2;
$text_color_4: #191833;

$text_color_5: #666666;
$text_color_6: #e1e1e1;
$text_color_7: #b63039;
$text_color_8 :#b5e61d;

$bg_color_1: #ffffff;
$bg_color_2: #000000;
$bg_color_3: #e3e7f0;
$bg_color_4: #B5B9BF;
$bg_color_5: 121.4deg, #5275BF 0%, #3C1354 0.01%, #5275BF 100%;
$bg_color_6: #210F33;
$bg_color_7: 92.31deg, rgba(0, 184, 242, 0.2) 0%, rgba(0, 184, 242, 0.15) 49.48%, rgba(0, 184, 242, 0.1) 100%;
$bg_color_8: 92.31deg, rgba(99, 190, 241, 0.25) 0%, rgba(99, 190, 241, 0.15) 49.48%, rgba(99, 190, 241, 0.1) 100%;
$bg_color_9: #00B8F2;
$bg_color_10: rgba(0, 184, 242, 0.1);
$bg_color_11: #CDCDDF;
$bg_color_12: rgba(255, 255, 255, 0.3);
$bg_color_13: #4E3677;
$bg_color_14: #08C65F;
$bg_color_15: #F3CF10;
$bg_color_16: 98.48% 326.98% at 0% 3.35%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.15) 100%;
$bg_color_17: #0080A8;
$bg_color_18: 121.4deg, #5275BF 0%, rgba(60, 19, 84, 0.9) 0.01%, rgba(82, 117, 191, 0.6) 100%;
$bg_color_19: rgba(60, 19, 84, 0.8);
$bg_color_20: rgba(63, 32, 99, 0.8);
$bg_color_21: #191833;
$bg_color_22: rgba(255, 255, 255, 0.85);
$bg_color_23: #887ba5;
$bg_color_24: rgba(76, 50, 113, 0.2);
$bg_color_25: #e5e5e5;
$bg_color_26: rgba(0, 0, 0, 0.6);
$bg_color_27: #009BFF;

$font_1: 'Poppins-Light', sans-serif;
$font_2: 'Poppins-Regular', sans-serif;
$font_3: 'Poppins-Medium', sans-serif;
$font_4: 'Poppins-SemiBold', sans-serif;
$font_5: 'Poppins-Bold', sans-serif;

@mixin scroll() {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: rem(4);          /* width of the entire scrollbar */
  }
  
  &::-webkit-scrollbar-track {
    margin: rem(5) 0;
    border-radius: rem(2);
    background: $bg_color_3;        /* color of the tracking area */
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: rem(2);
    background: $bg_color_4;  /* creates padding around scroll thumb */
  }
}



@mixin widget_wrapper {
  position:relative;
  display: flex;
  width: 100%;
  height: 100%;
  background: radial-gradient($bg_color_16);
  backdrop-filter: blur(rem(42));
  border-radius: rem(10);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: rem(10); 
    padding: rem(1); 
    background:radial-gradient(98.16% 354.35% at 98.16% 96.62%, rgba(255, 255, 255, 0.2) 0%, rgba(118, 123, 241, 0) 100%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(98.29% 354.82% at 98.29% 3.38%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%); 
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude; 
    z-index: -1;

    

 


  }
}

@mixin page_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

@mixin page_title {
  margin: 0 0 rem(40) 0;
  font-size: em(24);
  color: $text_color_1;
  font-family: $font_3;
  line-height: rem(34);
}

@mixin tooltip_wrapper() {
  background: linear-gradient($bg_color_5);
  padding: rem(12) rem(15);
  border-radius: rem(10);
  box-sizing: border-box;
  z-index: 10;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   left: rem(-3);
  //   top: calc(50% - 0.1875rem);
  //   border-top: rem(4) solid transparent;
  //   border-right: rem(4) solid $bg_color_19;
  //   border-bottom: rem(4) solid transparent;
  // }
}

@mixin tooltip_text() {
  font-size: em(12);
  color: $text_color_1;
  font-family: $font_2;
  line-height: rem(14);
}

.wt_header_wrapper {

background: url('./assets/img/rectangle.png');
height: rem(78);
border-radius: rem(10) rem(10) 0 0;
font-size: em(20);
font-family: $font_3;
color:white;
padding: rem(30) rem(50);
line-height: rem(28);
box-sizing: border-box;
border:rem(1) solid #d4d9ec;


}

.table-wrap-with-background {
  border-bottom-left-radius: rem(10);
  border-bottom-right-radius: rem(10);
  padding-bottom: 52px;
  background: white;
}