@import '../../themes/common.scss';

.create_user__wrapper {
  display: flex;
  flex-direction: column;
  width: rem(500);
  padding: rem(30) rem(50);
  box-sizing: border-box;

  .create_user__label {
    margin: rem(10) 0 rem(10) 0;
    font-size: em(16);
    color: $text_color_4;
    font-family: $font_2;
    line-height: rem(18);
  }

  .create_user__btn_wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: rem(40) 0 0 0;
  }

  .checkbox-temp-wrapper {
    display: flex;
    margin-top: 20px;
  }

  .temp__checkbox_label_text {
    font-family: "Poppins-Regular", sans-serif;
    margin-left: 10px;
  }

  .temp__checkbox_label_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .create_user__btn {
    width: rem(140);
    height: rem(54);
    margin: 0 0 0 rem(30);

    &:first-child {
      margin: 0;
    }
  }
}