@import '../../themes/common.scss';

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: rem(60) rem(60) rem(16) rem(60);
  box-sizing: border-box;

  .header__search_wrapper {
    width: rem(675);
  }

  .header__manage_wrapper {
    display: flex;
  }

  .header__manage_btn {
    margin: 0 rem(20) 0 0;

    &--mr32 {
      margin-right: rem(32);
    }
  }

  .header__manage_selectbox {
    position: relative;
  }

  .header__logout_btn {
    position: relative;
    display: flex;
    align-items: center;
    min-width: rem(100);
    width: rem(100);
    margin-left: 20px;
    min-height: rem(48);
    height: rem(48);
    padding: rem(12) rem(22);
    font-size: em(16);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(24);
    background-color: $bg_color_12;
    border: rem(0.5) solid $bg_color_11;
    backdrop-filter: blur(rem(56));
    border-radius: rem(6);
    box-sizing: border-box;
    cursor: pointer;
  }

  .userName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 130px;
  }

  .header__manage_selectbox_btn {
    position: relative;
    display: flex;
    align-items: center;
    min-width: rem(234);
    width: rem(234);
    min-height: rem(48);
    height: rem(48);
    padding: rem(12) rem(22);
    font-size: em(16);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(24);
    background-color: $bg_color_12;
    border: rem(0.5) solid $bg_color_11;
    backdrop-filter: blur(rem(56));
    border-radius: rem(6);
    box-sizing: border-box;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      min-width: rem(23);
      width: rem(23);
      min-height: rem(23);
      height: rem(23);
      margin: 0 rem(14) 0 0;
      background-image: url(assets/user.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }



    &::after {
      position: absolute;
      right: rem(22);
      content: '';
      display: block;
      min-width: rem(12);
      width: rem(12);
      min-height: rem(6);
      height: rem(6);
      background-image: url(assets/arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}