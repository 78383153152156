@import '../../themes/common.scss';

.changePassword__container_wrapper_modal {
  max-height: 600px;
  font-family: $font_2;
  padding: 30px;
  color:white;
  margin-top: 20px;
  display: block;
  width: 400px;

  .login {
    margin-bottom: 20px;
    font-size: 23px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    color:$text_color_4
}
  

  .content_wrapper {
    margin-top: 20px;

    .input__container_wrapper {
      margin-bottom: 20px;
      
    }

    .login__input_title {
      color:$text_color_4
    }

  }

}
