@import '../../themes/common.scss';

.downloadApp__container_wrapper {
  @include widget_wrapper;
  width: auto;
  max-height: 600px;
  font-family: $font_2;
  padding: 30px;
  color:white;
  margin-top: 20px;
  display: block;

  .content_wrapper {
    margin-top: 20px;
    max-width: 300px;

    a {
      width: 100%;
    height: 100%;
    font-size: 1em;
    color: #ffffff;
    font-family: "Poppins-Regular", sans-serif;
    line-height: 0.6875rem;
    border-radius: 10px;
    border: none;
    background-color: #0080A8;
    cursor: pointer;
    padding: 20px;
    text-decoration: none;
    margin-top: 20px;
    display: block;
    text-align: center;
    }

  }

}
