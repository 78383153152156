@import '../../themes/common.scss';

.login__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $bg_color_6;

  .login__content_wrapper {
    display: flex;
    flex-direction: column;
    width: rem(389);
    margin: rem(40) 0 0 0;
  }

  .logo {
    font-size: em(48);
    font-family: $font_4;
    color: white;
    span {
      color: $text_color_8;
    }
    margin-bottom: 20px;
  }

  .login__title {
    font-size: em(48);
    color: $text_color_3;
    font-family: $font_3;
  }

  .login__input_title {
    font-size: em(14);
    color: $text_color_6;
    font-family: $font_2;
    line-height: rem(10);
  }

  .login__input_wrapper {
    width: rem(393);
    height: rem(44);
    margin: rem(15) 0 rem(30) 0;
  }

  .login__checkbox_container_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .login__checkbox_label_wrapper {
    display: flex;
    align-items: center;
  }

  .login__checkbox_style.checkbox-label .checkbox-custom {
    border: 2px solid $bg_color_5;
  }

  .login__checkbox_style.checkbox-label input:checked ~ .checkbox-custom {
    background-color: $bg_color_3;
    border: 2px solid transparent;
  }

  .login__checkbox_style.checkbox-label input:checked ~ .checkbox-custom::after {
    border: solid $bg_color_6;
    border-width: 0 3px 3px 0;
  }

  .login__checkbox_label_text {
    padding: 0 0 0 rem(12);
    font-size: em(14);
    color: $text_color_6;
    font-family: $font_2;
    line-height: rem(10);
    cursor: pointer;
  }

  .login__forgot_pass_link {
    font-size: em(14);
    color: $text_color_3;
    font-family: $font_2;
    line-height: rem(10);
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .login__button_wrapper {
    width: rem(389);
    height: rem(50);
    margin: rem(20) 0 0 0;
  }

  .login__warning_wrapper {
    display: flex;
    align-items: center;;
    height: rem(14);
    margin: rem(16) 0 0 0;
  }

  .login__warning_icon {
    width: rem(14);
    height: rem(14);
    background-image: url('./assets/warning.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .login__warning_text {
    margin: 0 0 0 rem(10);
    font-size: em(12);
    color: $text_color_7;
    font-family: $font_2;
    line-height: rem(10);
  }
}