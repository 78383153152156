@import '../../themes/common.scss';

.modal_window__overlay_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(30) 0 0 rem(94);
  width: 100%;
  min-height: 100%;
  background-color: $bg_color_26;
  box-sizing: border-box;
  z-index: 9999;

  .modal_window__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 3.75rem);
  }

  .modal_window__container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: rem(10);
    box-sizing: border-box;
  }

  .modal_window__header_wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: rem(50);
    background-color: $bg_color_13;
    border: none;
    border-radius: rem(10) rem(10) 0 0;
  }

  .modal_window__header_title {
    font-size: em(18);
    color: $text_color_1;
    font-family: $font_3;
    line-height: rem(20);
  }

  .modal_window__header_close_btn {
    position: absolute;
    right: rem(30);
    width: rem(16);
    height: rem(16);
    background-color: transparent;
    background-image: url(assets/close.svg);
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  .modal_window__content_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0 0 rem(10) rem(10);
    box-sizing: border-box;
    background-color: $bg_color_25;
  }
}