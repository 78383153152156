@import '../../themes/common.scss';

.nodeCountWidget_wrapper {
  position: absolute;
  width: 242px;
  height: 178px;
  right: 92px;
  margin-top: 30px;
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

.line-1-header, .line-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
}
.line-1-header {
  line-height: 150%;
  margin-bottom: 12px;
  font-family: $font_4;
}
.line-1 {
  line-height: 150%;
  font-family: $font_2;
  margin-bottom: 10px;
  
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: block;
  margin-right: 10px;
}

.green-circle {
  @extend .circle;
background: #08C65F;

}

.red-circle {
  @extend .circle;
background: #EF0606;
}

.yellow-circle {
  @extend .circle;
background: #F3CF10;

}

.grey-cricle {
  @extend .circle;
  background: #9E9DAE;
}

.grey-text {
  color:#9E9DAE
}

.left-b {
  display: flex;
  justify-content:space-around;
  align-items:center;
}



}