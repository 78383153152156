@import '../../themes/common.scss';

.dashboard__wrapper {
  @include page_wrapper;

  .dashboard__title {
    @include page_title;
  }

  .dashboard__widgets_separator {
    display: flex;
    justify-content: space-between;
    margin: 0 0 rem(50) 0;
  }

  .dashboard__totalwidget_wrapper {
    width: calc(100% / 3 - 1.078125rem);
    min-height: rem(167);
    height: rem(167);
    margin: 0 rem(23) 0 0;

    &:last-child {
      margin: 0;
    }
  }

  .dashboard__widgets_graph_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: rem(485);
  }

  .dashboard__widgets_graph_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 rem(50) 0 rem(50);
  }

  .dashboard__widgets_graph_select {
    width: rem(135);
    height: rem(48);
  }

  .dashboard__linechart_content_wrapper {
    display: flex;
    width: 100%;
    height: rem(408);
    margin: rem(-1) 0 0 0;
    padding: rem(40);
    background-color: $bg_color_1;
    border-bottom-left-radius: rem(10);
    border-bottom-right-radius: rem(10);
    box-sizing: border-box;
  }

  .dashboard__linechart_widget_wrapper {
    width: calc(50% - 1.5625rem);
    margin: 0 rem(50) 0 0;

    &:last-child {
      margin: 0;
    }
  }

  .dashboard__linechart_widget_container {
    display: flex;
    flex-direction: column;
  }

  .dashboard__linechart_widget_title_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .dashboard__linechart_widget_title {
    margin: rem(7) 0 rem(35) 0;
    font-size: em(18);
    color: $text_color_2;
    font-family: $font_4;
    line-height: rem(25);
  }

  .dashboard__linechart_widget_value {
    margin: rem(7) 0 rem(35) 0;
    font-size: em(18);
    color: $text_color_2;
    font-family: $font_2;
    line-height: rem(25);
  }

  .dashboard__linechart_wrapper {
    width: 100%;
    min-height: rem(260);
    height: rem(260);
  }

  .dashboard__table_widget_wrapper {
    min-height: rem(599);
    height: rem(599);
    box-sizing: border-box;
  }

  .dashboard__table_wrapper {
    width: 100%;
    height: 100%;
  }
}