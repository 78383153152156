@import '../../themes/common.scss';

.total_widget__wrapper {
  @include widget_wrapper;
  align-items: center;
  padding: 0 rem(30);
  box-sizing: border-box;
  overflow: hidden;
  display: block;

  .total_widget__title {
    display: block;
    text-align: center;
    font-size: em(18);
    color: $text_color_1;
    font-family: $font_3;
    line-height: rem(25);
    padding: 30px 0;
  }

  .total_widget__value {
    font-size: em(48);
    text-align: center;
    color: $text_color_1;
    font-family: $font_3;
    line-height: rem(34);
  }
}