@import '../../themes/common.scss';

.action_btn__wrapper {
  display: flex;
  align-items: center;
  padding: rem(16) rem(20);
  font-size: em(16);
  color: $text_color_1;
  font-family: $font_2;
  line-height: rem(22);
  background-color: $bg_color_17;
  border: none;
  border-radius: rem(10);
  box-sizing: border-box;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: rem(17);
    height: rem(17);
    margin: 0 rem(10) 0 0;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &--download {
    &::before {
     background-image: url(assets/arrow-up.svg);
     transform: rotate(180deg); 
    }
  }
}