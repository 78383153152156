@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'), url("./assets/fonts/Poppins-Light.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local('Poppins-Regular'), url("./assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url("./assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local('Poppins-SemiBold'), url("./assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Bold";
  src: local('Poppins-Bold'), url("./assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("./assets/fonts/Poppins-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
