@import '../../themes/common.scss';

.lineChart__wrapper {
  width: 100%;
  height: 100%;
}

.lineChart__loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.lineChart__loader {
  width: rem(36);
  height: rem(36);
}

.areachart_widget__tooltip_wrapper {
  min-width: rem(129);
  
  @include tooltip_wrapper;

  .areachart_widget__tooltip_title {
    margin: 0 0 rem(10) 0;
    
    @include tooltip_text;
  }

  .areachart_widget__tooltip_item_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 0 rem(8) 0;

    &:last-child {
      margin: 0;
    }
  }

  .areachart_widget__tooltip_item_container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .areachart_widget__tooltip_item_color {
    min-width: rem(7);
    width: rem(7);
    min-height: rem(7);
    height: rem(7);
    margin: 0 rem(7) 0 0;
    border-radius: 50%;
  }

  .areachart_widget__tooltip_item_title {
    min-width: rem(60);
    margin: 0 rem(10) 0 0;
    font-size: em(12);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(14);
    white-space: nowrap;
  }

  .areachart_widget__tooltip_item_value {
    font-size: em(12);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(14);
    white-space: nowrap;
  }

  .areachart_widget__tooltip_content_time_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin: rem(10) 0 0 0;
  }

  .areachart_widget__tooltip_content_time_title {
    margin: 0 rem(5) 0 0;
    font-size: em(12);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(14);
  }

  .areachart_widget__tooltip_content_time_value {
    font-size: em(12);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(14);
  }
}