@import '../../themes/common.scss';

.file_placement__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: rem(10);

  .file_placement__title {
    width: 100%;
    padding: rem(19) rem(50);
    color: $text_color_1;
    font-family: $font_3;
    font-size: em(16);
    line-height: rem(22);
    background-color: $bg_color_20;
    border-top-left-radius: rem(10);
    border-top-right-radius: rem(10);
    box-sizing: border-box;
  }

  .wt_header_wrapper {
    border-bottom: 1px solid transparent;
  }

  .file_placement__main_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: rem(10);
    border-bottom-right-radius: rem(10);
    background-color: $bg_color_24;
    border: 1px solid #d4d9ec;
    border-top:1px solid transparent;
    box-sizing: border-box;
    margin-top: -1px;

    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }

  #tooltip-span {
    position:fixed;
    overflow:hidden;
  }

  .rsm-geographies {
    filter: drop-shadow(0px 2.8px 0.8px rgba(0, 0, 0, 0.2))
  }
  .tooltip-style {
    background: rgba(6, 6, 6, 0.85);
    border-radius: 3.8964px;
    padding:10px 12px;
    color:white;
    font-family: $font_1;
    font-size: 12px;
    line-height: 140%;
  }
  .tooltip-pad-1 {
    display: inline-block;
    width: 64px;
  }
  .countryFlag {
    display: inline-block;
    width: 13px;
    height: 10px;
    margin-right: 6px;
    
  }
  .countryFlag804 {
    background-image: url(img/ukr.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .countryFlag840 {
    background-image: url(img/usa.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .countryFlag076 {
    background-image: url(img/bra.png);
    background-repeat: no-repeat;
    background-size: contain;
  }


  .red-border {
    
  background: red;
  position: fixed;
  background: rgba(6, 6, 6, 0.85);
    border-radius: 3.8964px;
    padding:10px 12px;
    color:white;
    font-family: $font_1;
    font-size: 12px;


    a {
      cursor: pointer;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .controls {
    position: absolute;
    bottom: 82px;
    right: 70px;
    
    button {
      background: #0080a8;
      display: block;
      border:0;
      color:white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      width: 40px;
      height: 40px;
      
    }
    .btn-plus {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      margin-bottom: 1px;
    }
    .btn-minus {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

  }

}