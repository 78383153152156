@import '../../themes/common.scss';

.keyPairGenerator__container_wrapper {
  @include widget_wrapper;
  width: auto;
  max-height: 600px;
  font-family: $font_2;
  padding: 30px;
  color:white;
  margin-top: 20px;
  display: block;

  .content_wrapper {
    display: flex;
    margin-top: 40px;
    align-items: flex-start;
    flex-direction: column;

    .generate_btn_container {
      width: 50%;
      box-sizing: border-box;
      padding-left: 50px;
      padding-right: 50px;
    }
    .generate_content_container {
      box-sizing: border-box;
      margin-top: 20px;
     

      .privat_key {
        margin-top: 20px;
        border: 1px solid white;
        border-radius: 7px;
        min-height: 200px;
        padding: 20px;
        word-break: break-all;
        margin-bottom: 20px;
      }

    }

  }

}
