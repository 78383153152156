@import '../../themes/common.scss';

.file_props__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: rem(10);

  .file_props__title {
    width: 100%;
    padding: rem(30) rem(50);
    color: $text_color_1;
    font-family: $font_3;
    font-size: em(16);
    line-height: rem(22);
    box-sizing: border-box;
    background: url('./img/small.png');
    height: 78px;
    border-radius: 10px 10px 0px 0px;
    border:1px solid #d4d9ec;
  }

  .file_props__main_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: rem(10);
    border-bottom-right-radius: rem(10);
    background-color: $bg_color_22;
  }

  .file_props__content_wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: rem(30) 0 0 rem(56);
    box-sizing: border-box;
  }

  .file_props__content_title {
    margin: 0 0 rem(10) 0;
    color: $text_color_4;
    font-family: $font_3;
    font-size: em(16);
    line-height: rem(24);

    &--mb2 {
      margin-bottom: rem(2);
    }
  }

  .file_props__content_total {
    color: $text_color_2;
    font-family: $font_2;
    font-size: em(48);
    line-height: rem(72);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .file_props__content_block {
    display: flex;
    flex-direction: column;
    margin: 0 0 rem(20) 0;

    &:last-child {
      margin: 0;
    }
  }

  .file_props__content_value {
    color: $text_color_4;
    font-family: $font_2;
    font-size: em(16);
    line-height: rem(24);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}