@import '../../themes/common.scss';

.input__container_wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .input__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding: rem(10) rem(20);
    border-radius: 5px;
    border: 2px solid transparent;
    background-color: $bg_color_1;
    box-sizing: border-box;
    color: $text_color_5;
    font-family: $font_2;
    font-size: em(14);
    line-height: rem(10);
    transition: 0.2s;

    &:hover,
    &:focus {
      outline: none;
      border: 2px solid $text_color_3;
    }
  
    &::placeholder {
      font-size: em(14);
      color: $text_color_4;
      font-family: $font_2;
      line-height: rem(16);
    }
  }

  .input__wrapper--warning {
    border: 2px solid $text_color_7;

    &:hover,
    &:focus {
      border: 2px solid $text_color_7;
    }
  }

  .input__pass_wrapper {
   // padding: 0 rem(40) 0 rem(20);
  }

  .input__pass_wrapper.input__pass_text_spacing {
    letter-spacing: rem(2);
  }

  .input__eye_btn {
    position: absolute;
    top: rem(14);
    right: rem(15);
    width: rem(21);
    height: rem(18);
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  .input__eye_btn_open {
    top: rem(16);
    background-image: url('./assets/eye_open.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .input__eye_btn_close {
    background-image: url('./assets/eye_close.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
