@import '../../themes/common.scss';

.button__wrapper {
    width: 100%;
    height: 100%;
    font-size: em(16);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(11);
    border-radius: 10px;
    border: none;
    background-color: $bg_color_17;
    cursor: pointer;
    padding: 20px;

    &:hover
    {
      outline: none;
      background-color: $bg_color_9;
    }
  }

  .button__disabled_wrapper {
    background-color: $bg_color_4;

    &:hover,
    &:focus {
      background-color: $bg_color_4;
    }
  }