@import '../../themes/common.scss';
/* Styling Checkbox Starts */
.checkbox-label {
    display: block;
    position: relative;
    margin: auto;
    cursor: pointer;
    font-size: 22px;
    line-height: 24px;
    height: 20px;
    width: 20px;
    clear: both;
}

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 2px solid #FFFFFF;
}


.checkbox-label input:checked ~ .checkbox-custom {
    background-color: #FFFFFF;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity:1;
    border: 2px solid #FFFFFF;
}


.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 0;
    top: 10px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009BFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity:1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity:1;
  left: 4px;
  top: 0;
  width: 6px;
  height: 10px;
  border: solid #009BFF;
  border-width: 0 3px 3px 0;
  background-color: transparent;
  border-radius: 0;
}



/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);    
}

.checkbox-label input:checked ~ .checkbox-custom::before {
    left: -4px;
    top: -4px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    opacity:0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}




/* Style for Circular Checkbox */
.checkbox-label .checkbox-custom.circular {
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.checkbox-label input:checked ~ .checkbox-custom.circular {
    background-color: #FFFFFF;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}
.checkbox-label input:checked ~ .checkbox-custom.circular::after {
    border: solid #0067FF;
    border-width: 0 2px 2px 0;
}
.checkbox-label .checkbox-custom.circular::after {
    border-radius: 50%;
}

.checkbox-label .checkbox-custom.circular::before {
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.checkbox-label input:checked ~ .checkbox-custom.circular::before {
    border-radius: 50%;
}

.table__checkbox_style.checkbox-label {
    .checkbox-custom {
      border: 2px solid $bg_color_17;
    }

    & input:checked ~ .checkbox-custom {
      background-color: transparent;
      border: 2px solid $bg_color_27;
    }
  }

  .checkbox-label.disabled {
    .checkbox-custom {
        background-color: $bg_color_3;
        border: 2px solid $bg_color_4;
    }
  }
