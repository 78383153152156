@import '../../themes/common.scss';

.file_fragments__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: rem(10);

  .file_fragments__title {
    width: 100%;
    padding: rem(30) rem(50);
    color: $text_color_1;
    font-family: $font_3;
    font-size: em(16);
    line-height: rem(22);
    box-sizing: border-box;
    background: url('./img/small.png');
    height: 78px;
    border-radius: 10px 10px 0px 0px;
    border:1px solid #d4d9ec;
  }

  .file_fragments__main_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: rem(10);
    border-bottom-right-radius: rem(10);
    background-color: $bg_color_22;
  }

  .file_fragments__content_wrapper {
    display: flex;
    flex-direction: column;
    padding: rem(30) 0 0 rem(50);
    box-sizing: border-box;

    &:first-child {
      width: 40%;
    }

    &:last-child {
      width: 60%;
    }
  }

  .file_fragments__content_title {
    margin: 0 0 rem(5) 0;
    color: $text_color_4;
    font-family: $font_3;
    font-size: em(16);
    line-height: rem(24);
  }

  .file_fragments__content_block {
    display: flex;
    flex-direction: column;
    margin: 0 0 rem(20) 0;

    &:last-child {
      margin: 0;
    }
  }

  .file_fragments__content_value {
    color: $text_color_4;
    font-family: $font_2;
    font-size: em(16);
    line-height: rem(24);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .file_fragments__content_status {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      margin: 0 rem(8) 0 0;
      min-width: rem(12);
      width: rem(12);
      min-height: rem(12);
      height: rem(12);
      border-radius: 50%;
    }

    &--ok {
      &::before {
        background-color: $bg_color_14;
      }
    }

    &--warning {
      &::before {
        background-color: $bg_color_15;
      }
    }

    &--repairing {
      &::before {
        background-color: $bg_color_15;
      }
    }
    &--down {
      &::before {
        background-color: #EF0606;
      }
    }
  }
}