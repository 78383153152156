


.alert-wrap {
	
	position: absolute;
    z-index: 11100;
    width: 252px;
    left: 50%;
    margin-left: -126px;
    padding: 11px;
	border-radius: 5px;
	top: 1%;
	color:white;
	word-wrap: break-word;
	font-family: "Poppins-Regular", sans-serif;
	font-size: 12px;
   
	

}
.inner-box {
	text-align: center;
	word-break: break-all;
}
.reload-link {
	color:white;
	text-decoration: underline;
}
.reload-link:hover {
	color:white;
	text-decoration: underline;
	cursor: pointer;
}

.warning {
	-webkit-box-shadow: 0px 0px 59px -10px rgba(217,9,9,1);
	-moz-box-shadow: 0px 0px 59px -10px rgba(217,9,9,1);
	box-shadow: 0px 0px 59px -10px rgba(217,9,9,1);
	background: #B60012;
}

.success {
	background: rgba(21, 195, 60, 0.93);
	-webkit-box-shadow: 0px 0px 59px -10px rgba(21, 195, 60, 0.93);
	-moz-box-shadow: 0px 0px 59px -10px rgba(21, 195, 60, 0.93);
	box-shadow: 0px 0px 59px -10px rgba(21, 195, 60, 0.93);
}


.orange {
	background:#fa9603;
	font-weight: bold;
	width: 300px;
	
	-webkit-box-shadow: 0px 0px 59px -10px rgba(255, 188, 11, 0.77);
	-moz-box-shadow: 0px 0px 59px -10px rgba(255, 188, 11, 0.77);;
	box-shadow: 0px 0px 59px -10px rgba(255, 188, 11, 0.77);;
}
	
.close-alert {
	position: absolute;
    color: white;
    border: 1px solid white;
    top: -6px;
    right: -6px;
    background: #828282;
    padding: 3px;
    border-radius: 5px;
    font-size: 9px;
	padding: 3px 5px;
	cursor: pointer;
	text-decoration: none;
}	
.close-alert:hover {
	color:white;
}
	
	



