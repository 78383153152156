@import '../../themes/common.scss';

.changePassword__container_wrapper {
  @include widget_wrapper;
  width: auto;
  max-height: 600px;
  font-family: $font_2;
  padding: 30px;
  color:white;
  margin-top: 20px;
  display: block;

  .login {
    margin-bottom: 20px;
    font-size: 23px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}
  

  .content_wrapper {
    margin-top: 20px;
    max-width: 300px;

    .input__container_wrapper {
      margin-bottom: 20px;
    }

  }

}
