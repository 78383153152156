@import '../../themes/common.scss';

.button_wizard__wrapper {
    min-width: rem(95);
    width: 100%;
    height: 100%;
    padding: 0 rem(20);
    font-size: em(16);
    color: $text_color_1;
    font-family: $font_3;
    line-height: rem(18);
    border-radius: rem(10);
    background-color: $bg_color_4;
    border: none;
    box-sizing: border-box;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: $bg_color_9;
    }

    &:active {
      outline: none;
      background-color: $bg_color_9;
    }
  }

  .button_wizard__wrapper--active {
    color: $text_color_1;
    background-color: $bg_color_17;

    &:hover,
    &:focus {
      outline: none;
      background-color: $bg_color_18;
    }

    &:active {
      outline: none;
      background-color: $bg_color_18;
    }
  }

  .button_wizard__wrapper--disabled {
    color: $text_color_1;
    background-color: $bg_color_11;
    cursor: inherit;

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: $bg_color_11;
      box-shadow: none;
    }
  }
