@import '../../themes/common.scss';

.header_popup__wrapper {
  position: relative;

  .header_popup__container {
    position: absolute;
    bottom: auto;
    left: -20.6125rem;
    display: flex;
    flex-direction: column;
    width: rem(379);
    min-height: rem(101);
    margin: rem(7) 0 0 0;
    border: rem(0.5) solid $bg_color_11;
    background-color: $bg_color_12;
    backdrop-filter: blur(rem(56));
    border-radius: rem(6);
    box-sizing: border-box;
    z-index: 1;

    &--empty {
      justify-content: center;
      align-items: center;
    }
  }


  .header_popup__content_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: rem(204);
    padding: rem(20);
    box-sizing: border-box;
  
    @include scroll;
  }

  .header_popup__content_list_item {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: rem(24);
    height: rem(24);
    margin: 0 0 rem(11) 0;

    &:last-child {
      margin: 0;
    }

    &--events {
      .header_popup__content_list_item_icon {
        margin-right: rem(16);
        box-sizing: border-box;
      }

      .header_popup__content_list_item_value {
        &:nth-child(2) {
          width: rem(213);
          padding-right: rem(16);
        }

        &:last-child {
          width: rem(92);
          text-align: end;
        }
      }
    }
  }

  .header_popup__content_list_item_icon {
    min-width: rem(17);
    width: rem(17);
    min-height: rem(17);
    height: rem(17);
  }

  .header_popup__content_list_item_value {
    font-size: em(16);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(24);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    

     a {
       text-decoration: none;
       color: $text_color_1;
       cursor: pointer;
     }
  }

  .header_popup__description {
    width: rem(299);
    padding: rem(10) 0;
    font-size: em(12);
    color: $text_color_5;
    font-family: $font_2;
    line-height: rem(14);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }

  .header_popup__time {
    width: rem(132);
    padding: rem(10) 0;
    font-size: em(12);
    color: $text_color_5;
    font-family: $font_2;
    line-height: rem(14);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: right;
  }

  .header_popup__content_empty_text {
    font-size: em(16);
    color: $text_color_6;
    font-family: $font_4;
    line-height: rem(18);
    white-space: nowrap;
  }
}