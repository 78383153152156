@import '../../themes/common.scss';

.search__container_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: rem(48);
  height: rem(48);
  border: rem(0.5) solid $bg_color_11;
  border-radius: rem(6);
  background-color: $bg_color_12;
  backdrop-filter: blur(rem(56));
  box-sizing: border-box;

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(assets/search.svg)
      url(assets/close.svg);
  }

  &::before {
    content: '';
    position: absolute;
    left: rem(10);
    display: block;
    min-width: rem(35);
    width: rem(35);
    min-height: rem(22);
    height: rem(22);
    padding: 0 20px 0 0;
    background-image: url(assets/search.svg);
    background-repeat: no-repeat;
    background-size: rem(22);
    background-position: 0 0;
    border-right: rem(1) solid $bg_color_1;
    box-sizing: border-box;
  }

  .search__input_wrapper {
    width: 100%;
    height: 100%;
    padding: rem(12) rem(12) rem(12) rem(55);
    font-size: em(16);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(24);
    border: none;
    background-color: transparent;
    box-sizing: border-box;

    &::placeholder {
      font-size: em(16);
      color: $text_color_1;
      font-family: $font_2;
      line-height: rem(24);
    }

    &:hover,
    &:focus {
      outline: none;
    }
  }

  .search__btn_clear_wrapper {
    position: absolute;
    right: rem(10);
    width: rem(16);
    height: rem(16);
    border: none;
    background-color: transparent;
    background-image: url(assets/close.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
    }
  }
}
