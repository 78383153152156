@import '../../themes/common.scss';

.selectbox__wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  &--animated {
    display: flex;
    align-items: flex-end;
    min-height: rem(51);
    height: rem(51);
    margin: 0 0 rem(21) 0;
  }

  .selectbox__main_wrapper { 
    position: relative;
    height: 100%;

    &--animated {
      width: 100%;
    }
  }

  .selectbox__label {
    position:absolute;
    left: rem(10);
    bottom: rem(6);
    font-size: em(16);
    color: $text_color_4;
    font-family: $font_4;
    line-height: rem(19);
    pointer-events: none;
    transition: 0.2s ease all; 
    -moz-transition: 0.2s ease all; 
    -webkit-transition: 0.2s ease all;

    // &--active {
    //   bottom: rem(31);
    //   font-size: em(12);
    //   color: $text_color_7;
    //   line-height: rem(14);
    // }

    // &--disabled_filled {
    //   color: $text_color_10;
    // }

    // &--warning {
    //   color: $text_color_11;
    // }
  }

  .selectbox__readonly_wrapper {
    position: absolute;
    bottom: rem(9);
    right: rem(10);
    min-width: rem(16);
    width: rem(16);
    min-height: rem(16);
    height: rem(16);
  }
  
  .selectbox__readonly_icon {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(assets/readonly.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .selectbox__footer_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: rem(20);
    height: rem(20);
    padding: rem(6) 0 rem(1) 0;
    box-sizing: border-box;
  }

  .selectbox__warning_icon {
    min-width: rem(14);
    width: rem(14);
    min-height: rem(14);
    height: rem(14);
    margin: 0 rem(8) 0 0;
    background-image: url(assets/error.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .selectbox__warning_msg {
    font-size: em(12);
    // color: $text_color_11;
    font-family: $font_2;
    line-height: rem(14);
  }
}