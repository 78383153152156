@import '../../themes/common.scss';

.files_details__wrapper {
  @include page_wrapper;

  .files_details__content_wrapper {
    @include widget_wrapper;
    flex-direction: column;
    min-height: rem(1090);
    height: rem(1090);
    padding: rem(50) rem(40);
    box-sizing: border-box;
    backdrop-filter: none;
  }

  .files_details__title_wrapper {
    display: flex;
    align-items: center;
  }

  .files_details__title {
    @include page_title;

    &--route {
      &::after {
        content: '>';
        margin: 0 0 0 rem(8);
      }
      a {
        text-decoration: none;
        color:white;
      }
    }
  }

  .files_details__title_route {
    @include page_title;
    display: flex;
    align-items: center;
    margin-left: rem(12);

    &::before {
      content: '';
      display: block;
      margin: 0 rem(8) 0 0;
      min-width: rem(28);
      width: rem(28);
      min-height: rem(28);
      height: rem(28);
      background-image: url(assets/file.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .files_details__btn_panel_wrapper {
    display: flex;
    margin: 0 0 rem(38) 0;
  }

  .files_details__widget_panel_wrapper {
    display: flex;
    width: 100%;
    min-height: rem(261);
    height: rem(261);
    margin: 0 0 rem(40) 0;
  }

  .files_details__widget_wrapper {
    width: calc(50% - 1.25rem);
    margin: 0 rem(40) 0 0;

    &:last-child {
      margin: 0;
    }
  }

  .files_details__placement_wrapper {
    width: 100%;
    min-height: rem(689);
    height: rem(689);
    
    
  }
}