@import '../../themes/common.scss';

.download_file__wrapper {
  display: flex;
  flex-direction: column;
  width: rem(500);
  padding: rem(30) rem(50);
  box-sizing: border-box;

  .download_file__label {
    margin: 0 0 rem(20) 0;
    font-size: em(16);
    color: $text_color_4;
    font-family: $font_2;
    line-height: rem(18);
  }

  .download_file__btn_wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: rem(40) 0 0 0;
  }

  .download_file__btn {
    width: rem(140);
    height: rem(54);
    margin: 0 0 0 rem(30);

    &:first-child {
      margin: 0;
    }
  }
}