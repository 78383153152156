@import '../../themes/common.scss';

.switcher-link {
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 20px;
  border-radius: 11px;
  background-color: rgba(255, 255, 255, 0.3);
  font-family: "Poppins-Medium", sans-serif;
  cursor: pointer;
}